import { Meter } from './Meter';
import { IdService } from '../services/id.service';

export class Equipment {
    name = 'Equipment name n/a';
    id = `equipment-${ IdService.getRandomId()}`;
    meters: Array<Meter> = [];
    from: Date;
    to: Date;

    constructor() {
        const today = new Date();
        const nextMonth = new Date(new Date().setMonth(today.getMonth() + 1));

        this.from = today;
        this.to = nextMonth;
    }
}
