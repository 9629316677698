export class IdService {
    // Counter is initiated with random value
    static counter = Math.round(Math.random() * 100000000);

    /**
     * Returns incremented counter value
     */
    static getCounter(): string {
        return `${IdService.counter++}`.padStart(9, '0');
    }

    /**
     * Returns random number in string
     */
    static getRandom(): string {
        return `${Math.round(Math.random() * 100000000)}`.padStart(9, '0');
    }

    /**
     * Returns reandom id
     */
    static getRandomId(): string {
        return `${IdService.getCounter()}-${IdService.getRandom()}`;
    }

    /**
     * Returns reandom id, which contains provided planId
     */
    static getId(planId: string): string {
        return `${planId}-${IdService.getCounter()}-${IdService.getRandom()}`;
    }

    /**
     * Returns random id for a new production plan
     */
    static getProductionPlanId(): string {
        return `${Date.now().toString()}-${IdService.getCounter()}-${IdService.getRandom()}`;
    }
}
