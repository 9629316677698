import { ProductionPlannerComponent } from './plan-list/production-planner.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NodeService } from './services/node.service';
import { ProductionTargetComponent } from './production-diagram/production-target/production-target.component';
import { FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { ProductionDiagramComponent } from './production-diagram/production-diagram.component';
import { OperationDetailComponent } from './production-diagram/operation-detail/operation-detail.component';
import { SchedulesInOutComponent } from './production-diagram/operation-detail/schedules-inout/schedules-inout.component';
import { EquipmentMeterComponent } from './production-diagram/operation-detail/equipment-meter/equipment-meter.component';
import { MeterStatusesComponent } from './production-diagram/operation-detail/equipment-meter/meter-values/meter-statuses.component';
import { ConfirmationDialogComponent } from './production-diagram/confirmation-dialog/confirmation-dialog.component';
import { ImportPlanDialogComponent } from './production-diagram/import-plan-dialog/import-plan-dialog.component';

import { ProductionToolComponent } from './production-tool.component';
import { RouterModule } from '@angular/router';
import { ProductionPlanService } from './services/production-plan.service';
import { ColaborationPickerDialogComponent } from './plan-list/colaboration-dialog/colaboration-picker-dialog.component';
import { ConfirmationDialogDeleteComponent } from './plan-list/confirmation-dialog-delete/confirmation-dialog-delete.component';
import { SharedMaterialModule } from '../shared/shared-material.module';

const routes = [
    {
        path     : 'tool-page',
        data: {toolName: 'production-monitoring'},
        children: [
            {
                path: '',
                component: ProductionPlannerComponent,
                pathMatch: 'full',
            },
            {
                path: 'planner',
                component: ProductionToolComponent,
            },
        ]
    },
]
@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,

        FuseSidebarModule,
        FuseThemeOptionsModule,

        SharedMaterialModule
    ],
    declarations: [
        ProductionPlannerComponent,
        ColaborationPickerDialogComponent,
        ConfirmationDialogDeleteComponent,
        ProductionToolComponent,

        OperationDetailComponent,
        EquipmentMeterComponent,
        MeterStatusesComponent,
        SchedulesInOutComponent,

        ProductionDiagramComponent,
        ConfirmationDialogComponent,
        ImportPlanDialogComponent,
        ProductionTargetComponent
    ],
    providers: [ProductionPlanService, NodeService],
    entryComponents: [
        ColaborationPickerDialogComponent,
        ConfirmationDialogDeleteComponent,
        ProductionTargetComponent,

        OperationDetailComponent,
        EquipmentMeterComponent,
        MeterStatusesComponent,
        SchedulesInOutComponent,
        ConfirmationDialogComponent,
        ImportPlanDialogComponent
    ]
})
export class ProductionToolModule {}
