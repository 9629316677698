import { CustomData } from './CustomData';

export class DNode {
    id: string;
    custom_data: CustomData;

    constructor(id: string) {
        this.id = id;
    }
}
