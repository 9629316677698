import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'production-confirmation-dialog-delete',
    templateUrl: './confirmation-dialog-delete.component.html',
    styleUrls: ['./confirmation-dialog-delete.component.scss']
})
export class ConfirmationDialogDeleteComponent {
    id: string;
    description: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogDeleteComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        this.id = _data['id'] || 'id n/a';
        this.description = _data['description'] || 'description n/a';
    }

    /**
     * Close dialog with 'delete' message - the plan will be deleted
     */
    delete(): void {
        this.dialogRef.close('delete');
    }

    /**
     * Close dialog with 'cancel' message - user will not be navigated back
     */
    cancel(): void {
        this.dialogRef.close('cancel');
    }
}
