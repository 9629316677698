import { InOutItem } from './../../models/InOutItem';
import { SchedulesInOutComponent } from './../operation-detail/schedules-inout/schedules-inout.component';
import { NodeService } from '../../services/node.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Component, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Schedule } from '../../models';

@Component({
    selector: 'app-production-target',
    templateUrl: './production-target.component.html',
    styleUrls: ['./production-target.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ProductionTargetComponent {
    target: any;
    dialogRef: any;

    constructor(
        public _matDialogRef: MatDialogRef<ProductionTargetComponent>,
        public nodeService: NodeService,
        public _matDialog: MatDialog
    ) {
        this.target = this.nodeService.currentPlanDoc.target;
    }

    /**
     * Open pop-over dialog (SchedulesInOutComponent) to display details about the Target object defined by the index.
     */
    openSchedules(): void {
        this.dialogRef = this._matDialog.open(SchedulesInOutComponent, {
            data: {
                targetDetails: this.target,
                targets: true
            },
            panelClass: 'dialogWide'
        });
    }
}

class Target {
    id = 'id';
    level = 1;
    unit = InOutItem.defaultUnit;
    schedule: Array<Schedule> = [];
}
