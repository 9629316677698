import { Equipment } from './Equipment';
import { InOutItem } from './InOutItem';
import { DNodeType } from './DNodeType';
import { CustomData } from './CustomData';
import { ModelPlanOperationObject } from './PlanDocument';

/**
 * CustomDataOperation contains all the data, that is displayed within an operation node
 * TODO - operation node is the only type that is used
 */
export class CustomDataOperation extends CustomData {
    description: string;
    companyId: string;
    lineId: string;
    equipments: Array<Equipment> = [];
    inputs: Array<InOutItem> = [];
    outputs: Array<InOutItem> = [];

    pcsCurrent: number;
    pcsCurrentArr: Array<number> = [];
    pcsTarget: number;
    pcsTargetArr: Array<number> = [];
    equipmentstatusOK: number;
    onSchedule: number;

    constructor() {
        super();
        this.type = DNodeType.Operation;
    }

    /**
     * Take object of operation (usually as a response from server) and process it into the CustomDataOperation
     * @param planOperation - an operation object taken from operations array of PlanDocument
     */
    static loadCustomDataOperation(
        planOperation: ModelPlanOperationObject | CustomDataOperation
    ): CustomDataOperation {
        const customData = new CustomDataOperation();

        if (!planOperation) {
            planOperation = new ModelPlanOperationObject();
        }

        customData.level = +planOperation.level || 1;
        customData.name = planOperation.name || 'name n/a';
        customData.description = planOperation.description || 'description n/a';
        customData.equipments =
            planOperation.equipments || planOperation.equipments || [];
        customData.companyId = planOperation.companyId || 'company id n/a';
        customData.lineId = planOperation.lineId || 'line id n/a';

        // check status of all equipments
        let equipStatusOk = 1;
        let meterExists = false;

        if (customData.equipments) {
            for (const currentEquipment of customData.equipments) {
                if (currentEquipment.meters) {
                    for (const currentMeter of currentEquipment.meters) {
                        meterExists = true;
                        if (
                            currentMeter.statuses &&
                            currentMeter.statuses.length > 0 &&
                            (currentMeter.statuses[
                                currentMeter.statuses.length - 1
                            ].value < currentMeter.warningLowValue ||
                                currentMeter.statuses[
                                    currentMeter.statuses.length - 1
                                ].value > currentMeter.warningHighValue)
                        ) {
                            equipStatusOk = -1;
                            break;
                        }
                    }

                    if (equipStatusOk < 0) {
                        break;
                    }
                }
            }
        }

        if (!meterExists) {
            equipStatusOk = 0;
        }
        customData.equipmentstatusOK = equipStatusOk;

        if (planOperation.inputs) {
            customData.inputs = planOperation.inputs;
        } else {
            customData.inputs = [];
        }

        // BEGIN - check outputs for onSchedule property
        // ------------------------------------------------------------------------------
        let onSchedule = 1;
        let scheduleExists = false;

        if (planOperation.outputs) {
            customData.outputs = planOperation.outputs;
        } else {
            customData.outputs = [];
        }
        customData.pcsCurrentArr = [];
        customData.pcsTargetArr = [];

        if (planOperation.outputs) {
            for (const currentOut of planOperation.outputs) {

                if (currentOut.schedule) {
                    const tmpOutSchedule = [...currentOut.schedule];

                    if (tmpOutSchedule.length > 0) {
                        scheduleExists = true;

                        let sum = 0;
                        for (const currentOutSchedule of tmpOutSchedule) {
                            sum += Number(currentOutSchedule.quantity);
                        }
                        customData.pcsTargetArr.push(sum);
                    } else {
                        customData.pcsTargetArr.push(0);
                    }

                    let completedSum = 0;

                    // filter out only the COMPLETED schedule items
                    const tmpOutScheduleCompleted = tmpOutSchedule.filter(
                        value => {
                            return value.realDate;
                        }
                    );

                    for (const current of tmpOutScheduleCompleted) {
                        completedSum += current.quantity;
                    }

                    customData.pcsCurrentArr.push(completedSum);

                    // filter out only the NOT COMPLETED schedule items
                    let tmpOutScheduleTarget = tmpOutSchedule.filter(value => {
                        return value.realDate == null;
                    });

                    // sort the not completed schedules - the FIRST will be the nearest in future
                    tmpOutScheduleTarget = tmpOutScheduleTarget.sort(
                        (n1, n2) => {
                            const n1Date = new Date(n1.targetDate);
                            const n2Date = new Date(n2.targetDate);
                            if (n1Date > n2Date) {
                                return 1;
                            } else if (n1Date < n2Date) {
                                return -1;
                            }
                            return 0;
                        }
                    );

                    if (tmpOutScheduleCompleted.length > 0) {
                        if (
                            new Date(tmpOutScheduleCompleted[0].targetDate) <
                            new Date(tmpOutScheduleCompleted[0].realDate)
                        ) {
                            onSchedule = -1;
                        }
                    }

                    if (onSchedule > 0 && tmpOutScheduleTarget.length > 0) {
                        if (
                            new Date(tmpOutScheduleTarget[0].targetDate) <
                            new Date()
                        ) {
                            onSchedule = -1;
                        }
                    }
                }
            }
        }

        if (!scheduleExists) {
            onSchedule = 0;
        }

        customData.onSchedule = onSchedule;
        // END check outputs for onSchedule property
        // ------------------------------------------------------------------------------

        return customData;
    }
}
