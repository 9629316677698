import { NodeService } from './../../../../services/node.service';
import { Component, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { Equipment, Meter, MeterValue } from '../../../../models';

@Component({
    selector: 'meter-statuses',
    templateUrl: './meter-statuses.component.html',
    styleUrls: ['./meter-statuses.component.scss'],
    animations: fuseAnimations
})
export class MeterStatusesComponent {
    equipment?: Equipment;
    meter: Meter;

    metersDataSource: StatusesArrayDataSource;
    metersDisplayedColumns = ['statusDate', 'statusValue'];
    metersArraySubject = new BehaviorSubject<Array<MeterValue>>([]);

    constructor(
        public _matDialogRef: MatDialogRef<MeterStatusesComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public nodeService: NodeService
    ) {
        if (_data['equipment'] !== undefined) {
            this.meter = _data['meter'];
            this.metersArraySubject = new BehaviorSubject(this.meter.statuses);
            this.metersDataSource = new StatusesArrayDataSource(
                this.metersArraySubject
            );
            this.equipment = _data['equipment'];
        }
    }

    /**
     * Adds a new MeterValue object in the statuses array.
     */
    addStatus(): void {
        this.meter.statuses.push(new MeterValue());
        this.metersArraySubject.next(this.meter.statuses);
    }

    /**
     * Removes MeterValue object on the specified index from the statuses array.
     */
    removeStatus(index: number): void {
        this.meter.statuses.splice(index, 1);
        this.metersArraySubject.next(this.meter.statuses);
    }
}

// Statuses datasource helper class
class StatusesArrayDataSource extends DataSource<MeterValue> {
    /**
     * Constructor
     *
     * @param productionPlanService
     */
    constructor(
        public meterStatusestArrayChanged: BehaviorSubject<Array<MeterValue>>
    ) {
        super();
    }

    /**
     * Connect
     *
     * @returns {Observable<MeterValue[]>}
     */
    connect(): Observable<Array<MeterValue>> {
        return this.meterStatusestArrayChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {}
}
