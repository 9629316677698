import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subscription, Observable, Subject } from 'rxjs';
import { fuseAnimations } from '@fuse/animations/index';

import { NodeService } from '../services/node.service';
import { ProductionPlanService } from '../services/production-plan.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { DataSource } from '@angular/cdk/collections';
import { takeUntil } from 'rxjs/operators';
import { ColaborationPickerDialogComponent } from './colaboration-dialog/colaboration-picker-dialog.component';
import { ConfirmationDialogDeleteComponent } from './confirmation-dialog-delete/confirmation-dialog-delete.component';
import { PlanDocument } from '../models';

@Component({
    selector: 'production-planner',
    templateUrl: './production-planner.component.html',
    styleUrls: ['./production-planner.component.scss'],
    animations: fuseAnimations
})
export class ProductionPlannerComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any>;

    displayedColumns = ['collaborationId', 'planDescription', 'planButtons'];

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    dialogRef: any;
    dialogSubscription: Subscription;
    allPlansSubscription: Subscription;
    allPlans = Array<PlanDocument>();
    dataSource: ProductionPlanDataSource | null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private productionPlanService: ProductionPlanService,
        private nodeService: NodeService,
        public _matDialog: MatDialog
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.dataSource = new ProductionPlanDataSource(
            this.productionPlanService
        );
        this.productionPlanService.productionPlansChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(plans => {
                this.allPlans = plans;
            });

        this.productionPlanService.loadAllPlans();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Opens ColaborationPickerDialogComponent, which is used to select a collaboration, which is used to create a new plan
     */
    newPlan(): void {
        this.dialogRef = this._matDialog.open(
            ColaborationPickerDialogComponent,
            {
                data: {},
                panelClass: 'dialogMedium'
            }
        );
        this.dialogRef.afterClosed().subscribe(response => {
            if (response) {
                this.productionPlanService.createPlan('' + response);
            }
        });
    }

    /**
     * Navigate user on the planner view; load the PlanDocument for the specified id
     * @param docId
     */
    openPlanDocById(docId: string): void {
        this.nodeService.clear();
        this.nodeService.selectedPlan = docId;
        this.nodeService.loadPlan(
            this.productionPlanService.getPlanDocById(docId)
        );

        this.router.navigate(['planner'], { relativeTo: this.route });
    }

    /**
     * Open confirmation dialog to delete the selected plan
     * @param event
     * @param id - specifies the PlanDocument, which is about to be deleted
     */
    deletePlanDocById(event: UIEvent, id: string): void {
        event.stopPropagation();

        this.dialogRef = this._matDialog.open(
            ConfirmationDialogDeleteComponent,
            {
                data: {
                    planId: id,
                    description: this.productionPlanService.getPlanDocById(id)
                        .plan.description
                },
                panelClass: 'confirmation-dialog'
            }
        );

        this.dialogRef.afterClosed().subscribe(response => {
            if (response === 'delete') {
                this.productionPlanService.deletePlanDocumentById(id);
            }

            if (this.dialogSubscription) {
                this.dialogSubscription.unsubscribe();
            }
        });
    }
}

// ProductionPlan datasource helper class
export class ProductionPlanDataSource extends DataSource<PlanDocument> {
    /**
     * Constructor
     *
     * @param productionPlanService
     */
    constructor(private productionPlanService: ProductionPlanService) {
        super();
    }

    /**
     * Connect
     *
     * @returns {Observable<Array<PlanDocument>>}
     */
    connect(): Observable<Array<PlanDocument>> {
        return this.productionPlanService.productionPlansChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {}
}
