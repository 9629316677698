import { CustomDataOperation } from './CustomDataOperation';
import { DNode } from './DNode';

// Operation
export class DNodeOperation extends DNode {
    custom_data: CustomDataOperation = new CustomDataOperation();

    constructor(id: string) {
        super(id);
    }
}
