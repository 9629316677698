import { TargetSchedule } from './TargetSchedule';
import { InOutItem } from './InOutItem';
import { IdService } from '../services/id.service';

// Plan Object defines JSON object, that is used to (de)serialize plan
export class PlanDocument {
    id: string;
    price = 0;
    target: {
        id: string;
        level: number;
        unit: string;
        schedule: Array<TargetSchedule>;
    } = {
        id: 'n/a',
        level: 1,
        unit: 'unit',
        schedule: []
    };
    plan: {
        id: string;
        collaborationId: string;
        description: string;
        positions?: string;
        relations: Array<{
            id: string;
            source: string;
            target: string;
        }>;
        operations: Array<ModelPlanOperationObject>;
        accessRights: Array<any>;
    } = {
        id: 'plan-id',
        collaborationId: '',
        description: 'New plan',
        relations: [],
        operations: [],
        accessRights: []
    };

    constructor(colaborationId: string) {
        const productionPlanId = IdService.getProductionPlanId();
        const targetId = IdService.getId(productionPlanId);

        this.plan.collaborationId = colaborationId;
        this.plan.id = productionPlanId;

        this.target = {
            id: targetId,
            level: 1,
            unit: InOutItem.defaultUnit,
            schedule: []
        };

        // initialization of the final operation (which is bound to the target of the plan) and its input/output
        this.plan.operations.push(ModelPlanOperationObject.createModelPlanOperationObjectWithId(targetId));
        const inOutItemForTarget = new InOutItem();
        inOutItemForTarget.id = IdService.getId(productionPlanId);
        inOutItemForTarget.level = 1;
        inOutItemForTarget.unit = InOutItem.defaultUnit;
        this.plan.operations[0].inputs = [inOutItemForTarget];
    }
}

/**
 * PlanOperationObject defines single operation of PlanDocument
 */
export class ModelPlanOperationObject {
    id: string;
    level: number;
    name: string;
    description: string;
    companyId = `company-id-${IdService.getRandomId()}`;
    lineId = `line-id-${IdService.getRandomId()}`;
    equipments?: any[];
    equipment?: any[];
    inputs: Array<InOutItem> = [];
    outputs: Array<InOutItem> = [];

    static createModelPlanOperationObjectWithId(id: string): ModelPlanOperationObject {
        const result = new ModelPlanOperationObject();
        result.id = id;
        result.name = 'Product Completion';
        result.description = 'Product Completion';
        return result;
    }
}
