import { BehaviorSubject, timer, Subject, Subscription } from 'rxjs';
import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import * as vis from 'vis';

import { takeUntil } from 'rxjs/operators';
import { ProductionPlanService } from './production-plan.service';
import {
    DNode,
    DEdge,
    DNodeType,
    DNodeOperation,
    PlanDocument,
    CustomDataOperation,
    CustomData,
    TargetSchedule
} from '../models';
import { IdService } from './id.service';
import { ModelPlanOperationObject } from '../models/PlanDocument';
import { AuthenticationService } from '../../authentication/services/authentication.service';

declare var require: any;

@Injectable({
    providedIn: 'root'
})
export class NodeService implements OnDestroy {
    editMode = new BehaviorSubject(true);
    private editModeSubscription: Subscription;
    autoReloadActive = new BehaviorSubject(false);
    private autoreloadSubject = new Subject();
    reloadSelectedNode = new Subject();

    // reference on a vis.js network object, all nodes and edges are displayed within
    private network: vis.Network;
    nodes = new vis.DataSet([]);
    edges = new vis.DataSet([]);

    // layout is enabled for arrange feature, after arranging of the diagram, the layout is disabled - otherwise user would not be able to move the nodes
    private layoutEnabled = false;

    finalNode: DNodeOperation;
    currentPlanDoc?: PlanDocument;

    selectedPlan: string;
    selectedNode: DNode;
    selectedNodeEmitter = new EventEmitter<String>(); // next value is emmited when a node is (de)selected
    reloadEmitter = new EventEmitter<boolean>(); // next value is emmited when the netwrok was updated and shall be redrawn

    // holds the svg content of a node
    private operationNode;
    private bufferNode;

    private indicatorGreyImage;
    private indicatorRedImage;
    private indicatorGreenImage;

    private edgeFont = {
        align: 'horizontal',
        size: 36,
        color: '#505050'
    };
    private readonly indicatorGrey = 'rgba(128, 128, 128, 0.6)';

    constructor(private productionPlanService: ProductionPlanService,
                private authenticationService: AuthenticationService) {
        this.operationNode = require('./nodes-html/node.html');
        this.bufferNode = require('./nodes-html/node-buffer.html');

        this.toDataURL(
            require('./../../../../assets/images/tools/icons/na-icon.png'),
            dataUrl => {
                this.indicatorGreyImage = dataUrl;
            }
        );
        this.toDataURL(
            require('./../../../../assets/images/tools/icons/Problem-warning-icon.png'),
            dataUrl => {
                this.indicatorRedImage = dataUrl;
            }
        );
        this.toDataURL(
            require('./../../../../assets/images/tools/icons/check-1-icon.png'),
            dataUrl => {
                this.indicatorGreenImage = dataUrl;
            }
        );

        this.editModeSubscription = this.editMode.subscribe(value => {
            if (this.network) {
                this.network.setOptions({
                    interaction: {
                        dragNodes: value
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.editModeSubscription.unsubscribe();
    }

    //#region VIS.JS NETWORK HELPERS

    /**
     * Sets network object, that is going to be used by the service.
     * @param net - vis.js network object
     */
    setNetwork(net): void {
        this.network = net;

        this.network.setOptions({
            physics: {
                enabled: false
            }
        });
        for (const current of Object.keys(this.nodes['_data'])) {
            this.nodes.update({
                id: current,
                fixed: { x: false }
            });
        }
    }

    /**
     * Returns options object for creating a vis.js network object.
     */
    getOptions(): vis.Options {
        return {
            interaction: {
                multiselect: true,
                hover: true,
                dragNodes: this.editMode.value
            },
            manipulation: {
                enabled: false,
                addEdge: ((edgeData: any, callback: any): void => {
                    if (!this.editMode.value) {
                        return;
                    }
                    if (
                        edgeData.from === this.currentPlanDoc.target.id || // If source of the edge is the final node...
                        edgeData.from === edgeData.to
                    ) {
                        // ...or from and to is the same node...
                        return; // ...then the edge is not going to be added.
                    }
                    callback(edgeData);
                }).bind(this)
            },
            layout: {
                hierarchical: {
                    levelSeparation: 600,
                    enabled: this.layoutEnabled,
                    nodeSpacing: 380,
                    treeSpacing: 600,
                    edgeMinimization: true,
                    direction: 'RL'
                }
            },
            physics: {
                hierarchicalRepulsion: {
                    centralGravity: 0,
                    springConstant: 0
                },
                minVelocity: 0.75,
                solver: 'hierarchicalRepulsion',
                enabled: false
            },
            edges: {
                smooth: false,
                arrows: 'to',
                color: {
                    color: 'rgba(3, 155, 229, 0.6)',
                    highlight: 'rgba(3, 155, 229, 1)'
                }
            }
        };
    }

    /**
     * Set the selected node.
     * @param id - node id
     */
    setSelectedNode(id: string): void {
        if (id) {
            this.selectedNode = { ...this.nodes.get(id) };
        } else {
            this.selectedNode = null;
        }
        this.selectedNodeEmitter.emit(id);
    }

    /**
     * Updates the node with plain custom data.
     * Ususally used when a new node is created (vis.js creates plain new node with id, but without proper image)
     *
     * NOTE: all nodes are operation type
     *
     * @param nodeId -
     */
    prepareNewNode(nodeId: string): void {

        const operation = new ModelPlanOperationObject();
        operation.companyId = this.authenticationService.myCompanyId;

        this.updateNode(
            nodeId,
            CustomDataOperation.loadCustomDataOperation(operation)
        );
    }

    /**
     * Updates the node based on the provided customData - svg is loaded and updated respectively
     * @param nodeId - node id
     * @param customData - custom data of the node 
     */
    updateNode(nodeId: string, customData: CustomData): void {
        const parser = new DOMParser();
        let xmlDoc;

        switch (+customData.type) {
            case DNodeType.Operation:
                {
                    xmlDoc = parser.parseFromString(
                        this.operationNode,
                        'text/xml'
                    );

                    let pcsCurrent = (customData as CustomDataOperation).pcsCurrent;
                    let pcsTarget = (customData as CustomDataOperation).pcsTarget;
                    let unit = 'pcs';

                    if ((customData as CustomDataOperation).outputs && (customData as CustomDataOperation).outputs.length > 0) {
                        unit = (customData as CustomDataOperation).outputs[0].unit;
                    }

                    if ((customData as CustomDataOperation).pcsCurrentArr && (customData as CustomDataOperation).pcsCurrentArr.length > 0) {
                        pcsCurrent = (customData as CustomDataOperation).pcsCurrentArr[0];
                    }

                    if ((customData as CustomDataOperation).pcsTargetArr && (customData as CustomDataOperation).pcsTargetArr.length > 0) {
                        pcsTarget = (customData as CustomDataOperation).pcsTargetArr[0];
                    }

                    const operationCompleted = (pcsCurrent === pcsTarget);

                    const percentage = Math.round(100 * pcsCurrent / pcsTarget);
                    const progressBarPercentage = (100 - percentage) <= 100 ? (100 - percentage) < 0 ? 0 : (100 - percentage) : 100;


                    xmlDoc.getElementById('progress-pcs').innerText = pcsCurrent ? `${pcsCurrent} of ${pcsTarget} ${unit}`.substring(0, 20) : 'n/a';
                    xmlDoc.getElementById('progress-bar')
                        .setAttribute('style', `background-color: rgb(52, 182, 0); position: absolute;` +
                        `left: 0; top: 0; bottom: 0; border-radius: 50px; margin: 3px; right: ${progressBarPercentage}%;`);

                    switch ((customData as CustomDataOperation).onSchedule) {
                        case -1:
                            {
                                xmlDoc
                                    .getElementById('onschedule-label')
                                    .setAttribute(
                                        'style',
                                        `width: 200px; color: black;`
                                    );
                                xmlDoc
                                    .getElementById('onschedule-indicator')
                                    .setAttribute(
                                        'src',
                                        this.indicatorRedImage
                                    );
                            }
                            break;

                        case 1:
                            {
                                xmlDoc
                                    .getElementById('onschedule-label')
                                    .setAttribute(
                                        'style',
                                        `width: 200px; color: black;`
                                    );
                                xmlDoc
                                    .getElementById('onschedule-indicator')
                                    .setAttribute(
                                        'src',
                                        this.indicatorGreenImage
                                    );
                            }
                            break;

                        default: {
                            xmlDoc
                                .getElementById('onschedule-label')
                                .setAttribute(
                                    'style',
                                    `width: 200px; color: ${
                                        this.indicatorGrey
                                    };`
                                );
                            xmlDoc
                                .getElementById('onschedule-indicator')
                                .setAttribute('src', this.indicatorGreyImage);
                        }
                    }

                    if (nodeId === this.currentPlanDoc.target.id) {
                        xmlDoc.getElementById('progress-group').remove();
                        xmlDoc.getElementById('equipment').remove();
                        xmlDoc
                            .getElementById('rootsvg')
                            .setAttribute('height', '150px');
                    } else {
                        const equipmentOk: number = operationCompleted ? 0 : (customData as CustomDataOperation).equipmentstatusOK;
                        switch (equipmentOk) {
                            case -1:
                                {
                                    xmlDoc
                                        .getElementById(
                                            'equipment-status-label'
                                        )
                                        .setAttribute(
                                            'style',
                                            `width: 200px; color: black;`
                                        );
                                    xmlDoc
                                        .getElementById(
                                            'equipment-status-indicator'
                                        )
                                        .setAttribute(
                                            'src',
                                            this.indicatorRedImage
                                        );
                                }
                                break;

                            case 1:
                                {
                                    xmlDoc
                                        .getElementById(
                                            'equipment-status-label'
                                        )
                                        .setAttribute(
                                            'style',
                                            `width: 200px; color: black;`
                                        );
                                    xmlDoc
                                        .getElementById(
                                            'equipment-status-indicator'
                                        )
                                        .setAttribute(
                                            'src',
                                            this.indicatorGreenImage
                                        );
                                }
                                break;

                            default: {
                                xmlDoc
                                    .getElementById('equipment-status-label')
                                    .setAttribute(
                                        'style',
                                        `width: 200px; color: ${
                                            this.indicatorGrey
                                        };`
                                    );
                                xmlDoc
                                    .getElementById(
                                        'equipment-status-indicator'
                                    )
                                    .setAttribute(
                                        'src',
                                        this.indicatorGreyImage
                                    );
                            }
                        }
                    }
                }
                break;
        }

        const svgTitle = customData.name.substring(0, 50);

        xmlDoc.getElementById('title').innerText = svgTitle;
        const serializer = new XMLSerializer();
        const editedNode = serializer.serializeToString(xmlDoc.documentElement);

        // SELECTED
        // No need of this - just for better readability
        const xmlDocSelected = xmlDoc;

        xmlDocSelected
            .getElementById('node-container')
            .setAttribute(
                'style',
                'background-color: white; height: 100%; width: 100%; flex-direction: column; display: flex; border-radius: 10px;' +
                    'border: solid 2px rgba(3, 155, 229, 1); font-family: Sans-Serif; overflow: hidden; box-sizing: border-box;'
            );
        xmlDocSelected
            .getElementById('titleBackground')
            .setAttribute(
                'style',
                'color: white; background-color: rgba(3, 155, 229, 1); padding: 10px 12px; padding-bottom: 17px; box-sizing: border-box;'
            );

        const editedNodeSelected = serializer.serializeToString(
            xmlDocSelected.documentElement
        );

        // Generate SVGs
        const nodeSVG =
            'data:image/svg+xml;charset=utf-8,' +
            encodeURIComponent(editedNode);
        const nodeSVGSelected =
            'data:image/svg+xml;charset=utf-8,' +
            encodeURIComponent(editedNodeSelected);

        this.nodes.update({
            id: nodeId,
            label: null,
            image: {
                unselected: nodeSVG,
                selected: nodeSVGSelected
            },
            shape: 'image',
            shapeProperties: {
                useImageSize: true
            },
            custom_data: customData
        });
    }

    /**
     * Removes all nodes and edges and initiates reloading of the vis.js network object.
     */
    clear(): void {
        this.layoutEnabled = false;
        this.edges.clear();
        this.nodes.clear();

        if (this.finalNode) {
            this.nodes = new vis.DataSet([this.finalNode]);
            this.updateNode(this.finalNode.id, this.finalNode.custom_data);
            this.reloadEmitter.emit(true);
        }
    }

    //#endregion VIS.JS NETWORK HELPERS

    // ---------------------------------------------------------------------------------------------------------

    //#region ARRANGE FEATURE
    /**
     * The entry method for arranging the nodes.
     * Takes existing nodes and edges and arranges them in hierarchicaly order (vis.js feature).
     */
    arrangeNodes(): void {
        const nodeList = Array<NodeCapsule>();
        const edgeList = Array<EdgeCapsule>();

        // nodes vis.DataSet object - it contains properties named by ids of all the nodes { "node-id-1" : { id: node-id-1, ...node-object }}
        // for the purpose of further processing, all the nodes and edges are transformed in an array

        for (const current of Object.keys(this.nodes['_data'])) {
            const node = this.nodes['_data'][current];
            nodeList.push({
                id: current,
                custom_data: node.custom_data,
                level: null
            });
        }

        for (const current of Object.keys(this.edges['_data'])) {
            const edge = this.edges['_data'][current];
            edgeList.push({
                id: current,
                from: edge.from,
                to: edge.to,
                label: edge.label
            });
        }

        this.arragneListsOf(nodeList, edgeList);
    }

    /**
     * To be able to use the vis.js hirearchical layout, each of the node must have level property, which determines the level from the root of the diagram.
     * When the level property is determined for each of the nodes, the layout is turned on, network container is reloaded (layout is applied), and
     * layout is turned off again, so user can manipulate with the nodes.
     * @param nodeList - array of NodeCapsule that will be arranged
     * @param edgeList - array of EdgeCapsule
     */
    private arragneListsOf(
        nodeList: Array<NodeCapsule>,
        edgeList: Array<EdgeCapsule>
    ): void {
        // hierarchical layout is turned on - once the vis.network object is reloaded, this updated option will be used
        this.layoutEnabled = true;

        // determine level of each node - each node of the nodeList will have not-null level property
        this.levelizeGraph(nodeList, edgeList);

        // Remove "bubbles" - levels, which has no node - empty levels makes the diagram too wide with a lot of empty space. 
        // This can happen, when the diagram contains a cycle.
        this.levelizeRemoveBubbles(nodeList);

        // Create a new DataSet of nodes with defined level (necessary for hierarchical layout) - vis.js cannot work with array of nodes/edges
        this.nodes = new vis.DataSet([]);
        for (const current of nodeList) {
            this.nodes.add({
                id: current.id,
                label: 'a',
                level: current.level
            });
            this.updateNode(current.id, current.custom_data);
        }

        this.edges = new vis.DataSet([]);
        for (const current of edgeList) {
            this.edges.add({
                id: current.id,
                from: current.from,
                to: current.to,
                label: current.label,
                font: this.edgeFont
            });
        }

        // the vis.network object is reloaded with the hierarcial layout...
        this.reloadEmitter.emit(true);

        // ...and hierarchical layout is turned off again
        this.layoutEnabled = false;
        this.network.setOptions({
            layout: {
                hierarchical: {
                    enabled: false
                }
            }
        });
    }

    /**
     * Determine level of each node based on its position defined by edges.
     * @param nodeList - array of nodes that will be levelized based on their connections (edges)
     * @param edgeList - array of edges
     */
    private levelizeGraph(
        nodesList: Array<NodeCapsule>,
        edgesList: Array<EdgeCapsule>
    ): void {
        if (nodesList.length === 0) {
            return;
        }

        const nodes = Array<NodeCapsule>();
        const nodeStats = [];

        // determine the root of the diagram by finding the node, from which starts the least amount of edges
        // count of "froms" and "tos" (information defined by edges) are counted for each node in temporary array "nodeStats"
        for (const current of edgesList) {
            if (nodes[current.from] === undefined) {
                nodes[current.from] = {
                    froms: 0,
                    tos: 0,
                    fromList: [],
                    toList: [],
                    level: null
                };
            }

            if (nodes[current.to] === undefined) {
                nodes[current.to] = {
                    froms: 0,
                    tos: 0,
                    fromList: [],
                    toList: [],
                    level: null
                };
            }

            nodes[current.from].froms += 1;
            nodes[current.to].tos += 1;

            nodes[current.from].fromList.push(current.to);
            nodes[current.to].toList.push(current.from);
        }

        // nodeStats contains iformation about the count of edges, which leads from/to each node (defined by id) 
        for (const current of Object.keys(nodes)) {
            nodeStats.push({ ...nodes[current], id: current });
        }

        // Sorting node stats by "froms" - on the begin of the array, there will be the nodes which is more likely to be root(s).
        // That means these nodes do have minimum count of edges, which starts from this node(s).
        // The very first node will be on level 0 of the hierarchy layout.
        const sortedNodeStats = nodeStats.sort((obj1, obj2) => {
            if (obj1.froms > obj2.froms) {
                return 1;
            }
            if (obj1.froms < obj2.froms) {
                return -1;
            }
            return 0;
        });

        // There can be more than one node on the 0 level, if more than one node has equal count of edges, which are starting from the node.
        if (sortedNodeStats.length > 0) {
            const firstLevelFroms = sortedNodeStats[0].froms;
            for (let i = 0; i < sortedNodeStats.length; i++) {
                if (sortedNodeStats[i].froms === firstLevelFroms) {
                    nodes[sortedNodeStats[i].id].level = 0;
                } else {
                    break;
                }
            }
        }

        // LevelizeUpstream is called for each of the node, which does not have level property defined. 
        // Starting from the nodes, which is more likely to be closer to the root - this is determined by the sortedNodeStats array.
        for (let i = 0; i < sortedNodeStats.length; i++) {
            if (nodes[sortedNodeStats[i].id].level !== null) {
                this.levelizeUpstream(nodes, sortedNodeStats[i].id, []);
            }
        }

        // Level property is set for each of the NodeCapsule object - helper object, these cannot be directly used as a vis.DataSet object
        for (const current of Object.keys(nodes)) {
            for (const node of nodesList) {
                if (node.id === current) {
                    node.level = nodes[current].level;
                    break;
                }
            }
        }
    }

    /**
     * Determines level of the nodes, which are provided as parameter.
     * This methodn assumes, that the current node already have level value. The method goes by the edges, which are pointing to the current node, and sets
     * level value of the source nodes with a level value higher than the current node.
     * @param nodes - object of all nodes; "id" of node is used as property name
     * @param current - id of the currently evaluated node
     * @param searchedPath - history of the nodes, which were checked in past in order to determine level of the current node
     */
    private levelizeUpstream(
        nodes: Array<NodeCapsule>,
        current: string,
        searchedPath: Array<string>
    ): void {
        // level value of the current node is determined by its predecessor (edge connection which leads from current to predecessor)
        // there can be multiple predecessor, so only the deepest (the higher level value) is used
        for (const predecessor of nodes[current].toList) {
            if (
                nodes[predecessor].level === null ||
                nodes[predecessor].level <= nodes[current].level
            ) {
                const indexInPath = searchedPath.indexOf(predecessor);
                if (indexInPath > -1) {
                    // if the predecessor was already checked in order to determine level of the current node,
                    // then no other predecessors are going to be evaluated, since they were already checked - current node is a part of cycle
                    // the level value of the current node is reduced by the lenght of the cycle
                    // (searchPath lenght - the index, when the current predecessor was evaluated for the first time)
                    nodes[predecessor].level =
                        nodes[current].level -
                        (1 + searchedPath.length - indexInPath);
                    break;
                } else {
                    nodes[predecessor].level = nodes[current].level + 1;

                    // searchPath keeps history of the node, which were checked in order to determine level of the current node
                    // this is used to break circuit if the current node is a part of a cycle
                    const searchedPathPlus = [...searchedPath, predecessor];
                    this.levelizeUpstream(nodes, predecessor, searchedPathPlus);
                }
            }
        }
    }

    /**
     * Remove empty levels (level which has 0 nodes within) from the graph.
     * These "bubbles" occurrs, if the diagram contains a cycle
     * @param nodeList - array of nodes, which already have its level value
     */
    private levelizeRemoveBubbles(nodesList: Array<NodeCapsule>): void {
        const sortedNodes = nodesList.sort((obj1, obj2) => {
            if (obj1.level > obj2.level) {
                return 1;
            }
            if (obj1.level < obj2.level) {
                return -1;
            }
            return 0;
        });

        const nodesInLevels = [];
        for (const current of sortedNodes) {
            while (nodesInLevels.length <= current.level) {
                nodesInLevels.push(0);
            }
            nodesInLevels[current.level] = +nodesInLevels[current.level] + 1;
        }

        for (let lvl = 0; lvl < nodesInLevels.length; lvl++) {
            if (nodesInLevels[lvl] > 0) {
                continue;
            }
            const downstreamWithBubbles = sortedNodes.filter(
                object => object.level >= lvl
            );
            for (const current of downstreamWithBubbles) {
                current.level = current.level - 1;
            }
            nodesInLevels.splice(lvl, 1);
            lvl--;
        }
    }

    //#endregion ARRANGE FEATURE

    // ---------------------------------------------------------------------------------------------------------

    //#region PLAN (DE)SERIALIZATION
    /**
     * Parse JSON in string and call loadPlan
     * @param planResponse - string containing JSON representation of plan
     */
    parsePlanInputString(planResponse: string): void {
        const planDocument = JSON.parse(planResponse);
        this.loadPlan(planDocument);
    }

    /**
     * Loads nodes and edges from a PlanDocument object provided by server
     * @param doc - PlanDocument that is going to be loaded
     */
    loadPlan(doc: PlanDocument): void {
        this.currentPlanDoc = doc;

        if (doc == null || doc.plan == null) {
            return;
        }

        const plan = doc.plan;

        const nodesTmp: Array<DNode> = [];
        const edgesTmp: Array<vis.Edge> = [];

        // Process all operations and create operation nodes (DNode object).
        if (plan.operations) {
            for (const currentOp of plan.operations) {
                const operationNode = new DNodeOperation(currentOp.id);

                if (currentOp.id === this.currentPlanDoc.target.id) {
                    // place inputs to outputs for the final node, so its "On schedule" indicator is evaluated correctly (because it works only with outputs)
                    currentOp.outputs = currentOp.inputs;
                    this.finalNode = operationNode;
                }

                operationNode.custom_data = CustomDataOperation.loadCustomDataOperation(
                    currentOp
                );
                nodesTmp.push(operationNode);
            }
        }

        // Process all relations and create edges.
        if (plan.relations) {
            for (const current of plan.relations) {
                edgesTmp.push(
                    new DEdge(current.id, current.source, current.target)
                );
            }
        }

        this.nodes = new vis.DataSet([]);

        // If the PlanDocument contains information about positions of the nodes, it is applied.
        let positions = {};
        if (doc.plan.positions) {
            positions = JSON.parse(doc.plan.positions);
        }
        for (const current of nodesTmp) {
            if (positions[current.id]) {
                this.nodes.add({
                    id: current.id,
                    label: '',
                    x: positions[current.id].x,
                    y: positions[current.id].y
                });
            } else {
                this.nodes.add({ id: current.id, label: '' });
            }
            this.updateNode(current.id, current.custom_data);
        }

        // Transform array of edges to a vis.DataSet object.
        this.edges = new vis.DataSet([]);
        for (const current of edgesTmp) {
            this.edges.add({
                id: current.id,
                from: current.from,
                to: current.to,
                font: this.edgeFont
            });
        }

        if (this.network) {
            this.network.setOptions({
                layout: {
                    hierarchical: {
                        enabled: false
                    }
                }
            });

            this.reloadEmitter.emit(true);
        }
    }

    /**
     * Redraw the final node based on the latest data
     */
    updateFinalNode(): void {
        let finalNodeInOuts: any;

        if (this.finalNode.custom_data.inputs.length === 0) {

            // final node does not have any inputs, but it shall have one (same input as the target of the plan)

            finalNodeInOuts = {
                id: IdService.getId(this.currentPlanDoc.id),
                level: this.currentPlanDoc.target.level,
                unit: this.currentPlanDoc.target.unit,
                schedule: this.currentPlanDoc.target.schedule.map(
                    (item: TargetSchedule) => item.getSchedule()
                )
            };
        } else {

            // final node does already have the input, let's update its schedules per the plan target's schedules

            finalNodeInOuts = this.finalNode.custom_data.inputs[0];
            finalNodeInOuts.schedule = this.currentPlanDoc.target.schedule.map(
                (item: TargetSchedule) => item.getSchedule()
            );
        }

        this.finalNode.custom_data.inputs = [finalNodeInOuts];
        this.finalNode.custom_data.outputs = [finalNodeInOuts];

        this.updateNode(
            this.finalNode.id,
            CustomDataOperation.loadCustomDataOperation(
                this.finalNode.custom_data
            )
        );
    }

    /**
     * Returns PlanDocument representation of the current plan
     */
    serializePlan(): PlanDocument {
        const finalNodeWithoutOutputs = this.nodes.get(this.finalNode.id);
        if (finalNodeWithoutOutputs) {
            finalNodeWithoutOutputs.custom_data.outputs = [];
        }

        const productionTargetObject = {
            id: this.currentPlanDoc.target.id,
            level: this.currentPlanDoc.target.level,
            schedule: this.currentPlanDoc.target.schedule,
            unit: this.currentPlanDoc.target.unit
        };

        const planDetails = {
            id: this.currentPlanDoc.plan.id,
            description: this.currentPlanDoc.plan.description,
            collaborationId: this.currentPlanDoc.plan.collaborationId
        };

        const positions = this.network.getPositions();
        const doc = {
            id: this.currentPlanDoc.id,
            price: this.currentPlanDoc.price,
            target: productionTargetObject,
            plan: {
                ...planDetails,
                operations: [],
                relations: [],
                accessRights: [],
                positions: JSON.stringify(positions)
            }
        };

        for (const current of Object.keys(this.nodes['_data'])) {
            const node = this.nodes['_data'][current];
            const data = node.custom_data;

            doc.plan.operations.push({
                id: current,
                name: data.name,
                description: data.description,
                level: data.level,
                companyId: data.companyId,
                lineId: data.lineId,
                inputs: data.inputs,
                outputs: data.outputs,
                equipments: data.equipments
            });
        }

        for (const current of Object.keys(this.edges['_data'])) {
            const edge = this.edges['_data'][current];

            doc.plan.relations.push({
                id: edge.id,
                source: edge.from,
                target: edge.to,
                type: 'SEQUENTIAL',
                level: 1
            });
        }

        if (finalNodeWithoutOutputs) {
            finalNodeWithoutOutputs.custom_data.outputs = finalNodeWithoutOutputs.custom_data.inputs;
        }

        return doc;
    }

    //#endregion PLAN (DE)SERIALIZATION

    // ---------------------------------------------------------------------------------------------------------

    //#region UTILITY

    /**
     * Load data from url by simulating GET request, and calls the provided callback with the data as its parameter
     */
    private toDataURL(url: string, callback: (data: any) => void): void {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const reader = new FileReader();
            reader.onloadend = () => {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    /**
     * Toggle Autoreload feature - Autoreload reloads current plan from server in specified interval
     */
    toggleAutoreload(): void {
        if (this.autoReloadActive.value) {
            // STOPPING AUTORELOAD
            this.autoreloadSubject.next(true);
            this.autoreloadSubject.complete();
        } else {
            // STARTING AUTORELOAD
            if (this.autoreloadSubject) {
                this.autoreloadSubject.next(true);
                this.autoreloadSubject.complete();
            }

            this.autoreloadSubject = new Subject();
            timer(0, 2500)
                .pipe(takeUntil(this.autoreloadSubject))
                .subscribe(value => {
                    this.updateCurrentPlanFromServer();
                });
        }

        this.autoReloadActive.next(!this.autoReloadActive.value);
    }

    /**
     * Update current plan from server
     */
    updateCurrentPlanFromServer(): void {
        this.productionPlanService
            .reloadPlanDocById(this.currentPlanDoc.id)
            .subscribe((planDoc: PlanDocument) => {

                if (!planDoc) {
                    return;
                }

                for (const current of planDoc.plan.operations) {
                    this.updateNode(
                        current.id,
                        CustomDataOperation.loadCustomDataOperation(current)
                    );
                }
                if (this.selectedNode) {
                    this.setSelectedNode(this.selectedNode.id);
                    this.reloadSelectedNode.next();
                }
            });
    }

    //#endregion UTILITY
}

/**
 * NodeCapsule - Helper class used for arranging
 */

class NodeCapsule {
    constructor(
        public id: string,
        public custom_data: CustomData,
        public level: number
    ) {}
}

/**
 * EdgeCapsule - Helper class used for arranging
 */
class EdgeCapsule {
    constructor(
        public id: string,
        public from: string,
        public to: string,
        public label: string
    ) {}
}
