export class Schedule {
    static counter = 0;
    id: string;
    targetDate: Date;
    realDate: Date;
    quantity: number;

    constructor(item: any) {
        if (item) {
            this.id = item.id;
            this.targetDate = item.targetDate;
            this.realDate = item.realDate;
            this.quantity = item.quantity;
            return;
        }
        this.id = `${`${Math.round(Math.random() * 100000000)}`.padStart(
            9,
            '0'
        )}${Schedule.counter++}`;
        this.quantity = 0;
        this.targetDate = null;
        this.realDate = null;
    }
}
