import { HttpClient } from '@angular/common/http';

import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { TargetSchedule, PlanDocument } from '../models';
import { ModelPlanOperationObject } from '../models/PlanDocument';
import { SnackbarService } from 'app/main/shared/services/snackbar.service';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/services/authentication.service';

const url_production_monitoring_plans =
    'https://api.digicor-platform.net/production-monitoring/plans';

@Injectable({
    providedIn: 'root'
})
export class ProductionPlanService {
    private allProductionDocuments = Array<PlanDocument>();
    
    productionPlansChanged: BehaviorSubject<Array<PlanDocument>>;
    listOfPlasEmitter = new EventEmitter();

    constructor(
        private httpClient: HttpClient,
        private _authenticationService: AuthenticationService,
        private _snackbarService: SnackbarService
    ) {
        this.productionPlansChanged = new BehaviorSubject(
            this.allProductionDocuments
        );
    }

    /**
     * Return all ProductionPlans
     */
    getAllPlans(): Array<PlanDocument> {
        return this.allProductionDocuments;
    }

    /**
     * Load specified PlanDocument from server
     * @param id
     */
    reloadPlanDocById(id: string): Observable<PlanDocument> {

        return this.httpClient
            .get(`${url_production_monitoring_plans}/${id}`, 
                {
                    observe: 'body',
                    responseType: 'json',
                    headers: {
                        Authorization:
                            'Bearer ' +
                            this._authenticationService.token.getJwtToken()
                    }
                }
            )
            .pipe(
                catchError(error => {
                    // refresh token, if error occurrs
                    if (error) {
                        console.log(error);
                        this._authenticationService.refreshToken().subscribe(
                            result => {
                            }
                        );
                    }
                    return of(error); 
                })
            )
            .map(
                (value: PlanDocument): PlanDocument => {
                    if (value.plan) {
                        const plan = value;
                        this.sanitizePlan(plan);
                        return plan;
                    } else {
                        return null;
                    }
                }
            );
    }

    /**
     * Create a new plan for the specified collaboration id on server
     * @param collaborationId
     */
    createPlan(collaborationId: string): void {
        this.httpClient
            .post(
                url_production_monitoring_plans,
                new PlanDocument(collaborationId),
                {
                    observe: 'body',
                    responseType: 'json',
                    headers: {
                        Authorization:
                            'Bearer ' +
                            this._authenticationService.token.getJwtToken()
                    }
                }
            )
            .subscribe(
                (response: any) => {
                    if (response && response.id) {
                        this.loadAllPlans();
                    }
                },
                (error) => {
                    this._snackbarService.openSnackBar('Error: ' + error.status + ' ' + error.error.message, 2500);
                }
            );
    }

    /**
     * Send the PlanDocument on server - update an existing
     * @param document - PlanDocument object, which will be sent on server
     */
    updatePlan(document: PlanDocument): void {
        this.httpClient
            .put(
                `${url_production_monitoring_plans}/${document.id}`,
                document,
                {
                    observe: 'body',
                    responseType: 'json',
                    headers: {
                        Authorization:
                            'Bearer ' +
                            this._authenticationService.token.getJwtToken()
                    }
                }
            )
            .subscribe(
                () => {
                    this.loadAllPlans();
                },
                (error) => {
                    this._snackbarService.openSnackBar('Error: ' + error.status + ' ' + error.error.message, 2500);
                }
            );
    }

    /**
     * Delete specified plan on server
     * @param planDocId - id of a PlanDocument object
     */
    deletePlanDocumentById(planDocId: string): void {
        this.httpClient
            .delete(`${url_production_monitoring_plans}/${planDocId}`, {
                observe: 'body',
                responseType: 'json',
                headers: {
                    Authorization:
                        'Bearer ' +
                        this._authenticationService.token.getJwtToken()
                }
            })
            .subscribe(
                (response: any) => {
                    if (response === null) {
                        this.loadAllPlans();
                    }
                },
                (error) => {
                    this._snackbarService.openSnackBar('Error: ' + error.status + ' ' + error.error.message, 2500);
                }
            );
    }

    /**
     * Returns single PlanDocument object of specified id
     * @param planDocId
     */
    getPlanDocById(planDocId: string): PlanDocument {
        const planObject = this.allProductionDocuments.find(
            (element: PlanDocument) => element.id === planDocId
        );

        this.sanitizePlan(planObject);
        return planObject;
    }

    /**
     * Create Final Operation, if the selected plan does not have one. This should not happen, but it ensures the data from server side can be used.
     * Set all target schedule as TargetSchedule objects
     * @param plan - PlanDocument object, which will be sanitized
     */
    sanitizePlan(plan: PlanDocument): void {
        plan.target.schedule = plan.target.schedule.map(
            item => new TargetSchedule(item)
        );
        let finalNode = null;
        for (const operation of plan.plan.operations) {
            if (operation.id === plan.target.id) {
                finalNode = operation;
                break;
            }
        }

        if (!finalNode) {
            const finalOperation: ModelPlanOperationObject = new ModelPlanOperationObject();
            finalOperation.name = 'Product Completion';
            finalOperation.id = plan.target.id;

            plan.plan.operations.push(finalOperation);
        }
    }

    /**
     * Load all plans from server
     */
    loadAllPlans(): void {
        this.httpClient
            .get(url_production_monitoring_plans, {
                observe: 'body',
                responseType: 'json',
                headers: {
                    Authorization:
                        'Bearer ' +
                        this._authenticationService.token.getJwtToken()
                }
            })
            .subscribe(
                (response: any) => {
                    this.allProductionDocuments = response.plans;
                    this.emitUpdatedList();
                },
                (error) => {
                    this._snackbarService.openSnackBar('Error: ' + error.status + ' ' + error.error.message, 2500);
                }
            );
    }

    /**
     * Emmit a new event with copy of all ProductionPlans
     */
    emitUpdatedList(): void {
        this.productionPlansChanged.next([...this.allProductionDocuments]);
    }
}
