import { NodeService } from './../../../services/node.service';
import { Component, Inject, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { InOutItem, Schedule, TargetSchedule } from '../../../models';
import { SnackbarService } from 'app/main/shared/services/snackbar.service';
import { SharedEnums } from 'app/main/shared/shared-enums';
import * as moment from 'moment';

@Component({
    selector: 'schedules-inout',
    templateUrl: './schedules-inout.component.html',
    styleUrls: ['./schedules-inout.component.scss'],
    animations: fuseAnimations
})
export class SchedulesInOutComponent {
    inoutItem: InOutItem;

    schedules = new Array<Schedule>();
    schedulesDataSource: SchedulesArrayDataSource;
    schedulesColumns = [
        'scheduleQuantity',
        'scheduleTargetDate',
        'scheduleRealDate',
        'scheduleButtons'
    ];
    schedulesArraySubject = new BehaviorSubject<Array<Schedule>>([]);

    input = true;
    targets = false;
    units: Array<string>;
    targetDetails = { id: '', level: 1, unit: InOutItem.defaultUnit };
    minDate = moment();

    constructor(
        public _matDialogRef: MatDialogRef<SchedulesInOutComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public nodeService: NodeService,
        private _snackbarService: SnackbarService,
        private _sharedEnums: SharedEnums
    ) {
        this.updateData(_data);
        this.units = [ InOutItem.defaultUnit , ..._sharedEnums.unitsEnum];
    }

    @Input()
    set data(data: any) {
        this.updateData(data);
    }

    /**
     * Allows to update component with new data. This is particulary useful, when the component is embeded in another component, and it is not used as a modal dialog.
     * @param _data
     */
    updateData(_data: any): void {
        if (!_data) {
            this.schedules = [];
            this.schedulesArraySubject.next(this.schedules);
            this.schedulesDataSource = new SchedulesArrayDataSource(
                this.schedulesArraySubject
            );
            return;
        }

        if (_data['targets'] === true) {
            this.targets = true;

            this.targetDetails = _data['targetDetails'];

            this.schedules = _data['targetDetails'].schedule;
            this.schedulesArraySubject.next(this.schedules);
            this.schedulesDataSource = new SchedulesArrayDataSource(
                this.schedulesArraySubject
            );
        } else {
            this.input = _data['input'];

            this.inoutItem = _data['inOutItem'];

            this.schedules = _data['inOutItem'].schedule;
            this.schedulesArraySubject.next(this.schedules);
            this.schedulesDataSource = new SchedulesArrayDataSource(
                this.schedulesArraySubject
            );
        }
    }

    /**
     * Adds a new Schedule or TargetSchedule object in the schedules array.
     */
    addSchedule(): void {
        if (this.targets) {
            this.schedules.push(new TargetSchedule(null));
        } else {
            this.schedules.push(new Schedule(null));
        }
        this.schedulesArraySubject.next(this.schedules);
    }

    /**
     * Remove object specified by index from the schedules array.
     * @param index
     */
    removeSchedule(index: number): void {
        this.schedules.splice(index, 1);
        this.schedulesArraySubject.next(this.schedules);
    }

    /**
     * Closes the component, if it is used as a modal dialog.
     */
    close(): void {
        let canBeClosed = true;
        for (const current of this.schedules) {
            if (current.targetDate === null) {
                canBeClosed = false;
                break;
            }
        }
        if (canBeClosed) {
            this._matDialogRef.close();
        } else {
            this._snackbarService.openSnackBar('Please fill in all Target Date values', 2500);
        }
    }
}

// Schedules datasource helper class
class SchedulesArrayDataSource extends DataSource<Schedule> {
    /**
     * Constructor
     *
     * @param productionPlanService
     */
    constructor(
        public schedulesArrayChanged: BehaviorSubject<Array<Schedule>>
    ) {
        super();
    }

    /**
     * Connect
     *
     * @returns {Observable<Schedule[]>}
     */
    connect(): Observable<Array<Schedule>> {
        return this.schedulesArrayChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {}
}
