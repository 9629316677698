import { Subscription } from 'rxjs';
import { ProductionPlanService } from '../services/production-plan.service';
import { NodeService } from '../services/node.service';
import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    HostListener,
    OnDestroy
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ProductionTargetComponent } from './production-target/production-target.component';
import { OperationDetailComponent } from './operation-detail/operation-detail.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ImportPlanDialogComponent } from './import-plan-dialog/import-plan-dialog.component';

import * as vis from 'vis';
import { DataSet } from 'vis';
import { DNode, DNodeType, CustomDataOperation } from '../models';

@Component({
    selector: 'app-production-diagram',
    templateUrl: './production-diagram.component.html',
    styleUrls: ['./production-diagram.component.scss']
})
export class ProductionDiagramComponent implements OnInit, OnDestroy {
    @ViewChild('siteConfigNetwork') networkContainer: ElementRef;
    network: vis.Network;

    dialogRef: any;
    dialogSubscription: Subscription;

    productionViewHeight: number;
    title: string;

    addNodeMode = false;
    addEdgeMode = false;
    editModeSubscription: Subscription;
    autoreloadSubscription: Subscription;

    constructor(
        public nodeService: NodeService,
        private route: ActivatedRoute,
        private router: Router,
        private productionPlanService: ProductionPlanService,
        public _matDialog: MatDialog
    ) {
        this.editModeSubscription = this.nodeService.editMode.subscribe(
            value => {
                if (!value) {
                    this.addNodeMode = false;
                    this.addEdgeMode = false;
                }
            }
        );
    }

    ngOnInit(): void {
        if (!this.nodeService.currentPlanDoc) {
            // Page was reloaded - currentPlanDoc is undefined, there is nothing to display

            this.router.navigate(['..'], { relativeTo: this.route });
        } else {
            // PlanDocument was selected

            // resize window - due to vis library
            this.productionViewHeight = window.innerHeight;

            this.nodeService.reloadEmitter.subscribe(() => {
                this.loadVisTree(this.getTreeData());
            });

            const treeData = this.getTreeData();
            this.loadVisTree(treeData);
            setTimeout(() => {
                this.network.fit();
            }, 0);

            this.title = this.nodeService.currentPlanDoc.plan.description;
        }
    }

    ngOnDestroy(): void {
        if (this.nodeService.autoReloadActive.value) {
            this.nodeService.toggleAutoreload();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.productionViewHeight = window.innerHeight;
    }

    /**
     * Create new Network object with the provided nodes and edges
     * @param treedata - takes object { nodes: DataSet, edges: DataSet } with the data to insert
     */
    loadVisTree(treedata: {
        nodes: DataSet<DNode>;
        edges: DataSet<vis.Edge>;
    }): void {
        const options = this.nodeService.getOptions();

        const container = this.networkContainer.nativeElement;
        this.network = new vis.Network(container, treedata, options);
        this.network.on('click', params => {
            if (params.nodes.length > 0) {
                let containsFinalNode = false;
                for (const current of params.nodes) {
                    if (
                        this.nodeService.nodes.get(current)['id'] ===
                        this.nodeService.currentPlanDoc.target.id
                    ) {
                        containsFinalNode = true;
                        break;
                    }
                }
                if (containsFinalNode) {
                    this.nodeService.setSelectedNode(
                        this.nodeService.finalNode.id
                    );
                } else {
                    this.nodeService.setSelectedNode(null);
                }
            }
        });
        this.network.on('doubleClick', params => {
            if (params.nodes.length > 0) {
                if (params.nodes[0]) {
                    if (
                        this.nodeService.nodes.get(params.nodes[0])['id'] ===
                        this.nodeService.currentPlanDoc.target.id
                    ) {
                        this.showProductionTarget();
                        return;
                    }

                    this.nodeService.setSelectedNode(params.nodes[0]);
                } else {
                    this.nodeService.setSelectedNode(null);
                    return;
                }

                this.dialogRef = this._matDialog.open(OperationDetailComponent, {
                    panelClass: 'dialogWide'
                });

                this.dialogSubscription = this.dialogRef
                    .afterClosed()
                    .subscribe(() => {
                        if (
                            this.nodeService.selectedNode.custom_data.type ===
                            DNodeType.Operation
                        ) {
                            this.nodeService.updateNode(
                                this.nodeService.selectedNode.id,
                                CustomDataOperation.loadCustomDataOperation(<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                            );
                        }

                        this.dialogSubscription.unsubscribe();
                    });
            }
        });

        this.nodeService.setNetwork(this.network);
    }

    /**
     * Returns { nodes, edges } object and adds add handlers for both
     * @param nodes
     * @param edges
     */
    getTreeData(): { nodes: DataSet<DNode>; edges: DataSet<vis.Edge> } {
        // when node is added, "prepareNewNode" is called, so proper image is set to the new node; by default vis.js would use a blue circle
        this.nodeService.nodes.on('add', (event, properties) => {
            this.nodeService.prepareNewNode(properties.items[0]);
            this.addNodeMode = false;
        });

        this.nodeService.edges.on('add', () => {
            this.addEdgeMode = false;
        });

        const treeData = {
            nodes: this.nodeService.nodes,
            edges: this.nodeService.edges
        };
        return treeData;
    }

    /**
     * Prior to navigating back, confirmation dialog is displayed, so user can save/discard current plan, or cancel the action
     */
    navigateBack(): void {
        this.dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
            data: {},
            panelClass: 'confirmation-dialog'
        });

        this.dialogRef.afterClosed().subscribe(response => {
            this.onBack();

            switch (response) {
                case 'save':
                    this.savePlan();
                    this.router.navigate(['..'], { relativeTo: this.route });
                    break;
                case 'cancel':
                    break;
                case 'discard':
                    this.router.navigate(['..'], { relativeTo: this.route });
                    break;
            }

            if (this.dialogSubscription) {
                this.dialogSubscription.unsubscribe();
            }
        });
    }

    /**
     * Sets network in addNode mode
     */
    addNode(): void {
        this.addNodeMode = true;
        this.network.addNodeMode();
    }

    /**
     * Delete selected node or edge
     */
    deleteSelected(): void {
        this.addNodeMode = false;

        if (
            this.nodeService.selectedNode != null &&
            this.nodeService.selectedNode.id ===
                this.nodeService.currentPlanDoc.target.id
        ) {
            return;
        }
        this.network.deleteSelected();
    }

    /**
     * Sets network in addEdge mode
     */
    addEdge(): void {
        this.addEdgeMode = true;
        this.network.addEdgeMode();
    }

    /**
     * Cancels addEdge or addNode modes
     */
    onBack(): void {
        this.addNodeMode = false;
        this.addEdgeMode = false;

        this.network.disableEditMode();
    }

    /**
     * Toggles edit mode
     */
    toggleEditMode(): void {
        if (this.nodeService.editMode.value === true) {
            this.network.disableEditMode();
        } else {
            this.network.enableEditMode();
        }
        this.nodeService.editMode.next(!this.nodeService.editMode.value);
    }

    toggleAutoReload(): void {
        this.nodeService.toggleAutoreload();
    }

    /**
     * Show production target dialog
     */
    showProductionTarget(): void {
        this.dialogRef = this._matDialog.open(ProductionTargetComponent, {
            panelClass: 'dialogWide',
            disableClose: true
        });

        this.dialogSubscription = this.dialogRef.afterClosed().subscribe(() => {
            this.nodeService.updateFinalNode();
            this.dialogSubscription.unsubscribe();
        });
    }

    /**
     * Clear nodes from network; Only the final node remains
     */
    clear(): void {
        this.nodeService.clear();
    }

    /**
     * Arrange nodes in hierarchial layout
     */
    arrange(): void {
        this.nodeService.arrangeNodes();
    }

    /**
     * Save current plan
     */
    savePlan(): void {
        const planToSave = this.nodeService.serializePlan();
        this.productionPlanService.updatePlan(planToSave);
    }

    /**
     * Show import dialog; Allows user to import JSON representation of a plan and load it
     */
    importJSON(): void {
        this.dialogRef = this._matDialog.open(ImportPlanDialogComponent, {
            data: {},
            panelClass: 'dialogWide'
        });

        this.dialogSubscription = this.dialogRef
            .afterClosed()
            .subscribe(response => {
                if (response) {
                    const doc = JSON.parse(response);
                    if (doc) {
                        this.nodeService.clear();
                        this.nodeService.loadPlan(doc);
                    }
                }
                this.dialogSubscription.unsubscribe();
            });
    }

    /**
     * Export current plan in a JSON object in a file and initiate emulated "download"
     */
    exportJSON(): void {
        const stringPlan = this.nodeService.serializePlan();

        // Prepare download link element
        const hrefElement = document.createElement('a');
        hrefElement.setAttribute('style', 'display: none');
        hrefElement.setAttribute('download', 'plan.json'); // Would be nice to add plan id
        hrefElement.setAttribute(
            'href',
            'data:text/json;charset=UTF-8,' +
                encodeURIComponent(JSON.stringify(stringPlan, null, 4))
        );

        // Add hidden element to DOM
        document.body.appendChild(hrefElement);
        hrefElement.click(); // Invoke click event

        // Remove element from DOM
        document.body.removeChild(hrefElement);
    }
}
