import { MeterValue } from './MeterValue';
import { IdService } from '../services/id.service';

export class Meter {
    id = `meter-${IdService.getRandomId()}`;
    unit = 'unit';
    warningLowValue = 0;
    warningHighValue = 100;
    statuses: Array<MeterValue> = [];
}
