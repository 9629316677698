import { EquipmentMeterComponent } from './equipment-meter/equipment-meter.component';
import { NodeService } from '../../services/node.service';
import { fuseAnimations } from '@fuse/animations/index';
import {
    Component,
    Inject,
    ViewEncapsulation,
    OnInit,
    OnDestroy
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SchedulesInOutComponent } from './schedules-inout/schedules-inout.component';
import { InOutItem, Equipment, CustomDataOperation, DNode, DNodeType } from '../../models';

@Component({
    selector: 'operation-detail',
    templateUrl: './operation-detail.component.html',
    styleUrls: ['./operation-detail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class OperationDetailComponent implements OnInit, OnDestroy {
    dialogRef: any;

    // inputs
    inputDataSource: InOutArrayDataSource;
    inputDisplayedColumnes = [
        'inputId',
        // 'inputLevel',
        'inputUnit',
        'inputButtons'
    ];
    inputArraySubject = new BehaviorSubject<Array<InOutItem>>([]);

    // outputs
    outputDataSource: InOutArrayDataSource;
    outputDisplayedColumnes = [
        'outputId',
        // 'outputLevel',
        'outputUnit',
        'outputProgressBar',
        'outputButtons'
    ];
    outputArraySubject = new BehaviorSubject<Array<InOutItem>>([]);
    outputPercentagesFalse = [];
    outputPercentagesTrue = [];

    // equipment
    equipmentDataSource: EquipmentArrayDataSource;
    equipmentDisplayedColumns = [
        'equipmentId',
        'equipmentName',
        // 'equipmentFrom',
        // 'equipmentTo',
        'equipmentButtons'
    ];
    equipmentArraySubject = new BehaviorSubject<Array<Equipment>>([]);

    reloadSelectedNodeSubscription: Subscription;

    constructor(
        public nodeService: NodeService,
        public _matDialog: MatDialog,
        public _matDialogRef: MatDialogRef<OperationDetailComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {
        if (this.nodeService.selectedNode && this.nodeService.selectedNode.custom_data.type === DNodeType.Operation) {
            this.inputArraySubject.next(
                (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                    .inputs
            );
            this.inputDataSource = new InOutArrayDataSource(
                this.inputArraySubject
            );

            this.outputArraySubject.next(
                (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                    .outputs
            );
            this.outputDataSource = new InOutArrayDataSource(
                this.outputArraySubject
            );

            this.equipmentArraySubject.next(
                (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                    .equipments
            );
            this.equipmentDataSource = new EquipmentArrayDataSource(
                this.equipmentArraySubject
            );
        }
    }

    ngOnInit(): void {
        if (!(this.nodeService.selectedNode) || this.nodeService.selectedNode.custom_data.type !== DNodeType.Operation) {
            this._matDialogRef.close();
            return;
        } 

        this.updateOutputProgressBars();

        this.reloadSelectedNodeSubscription = this.nodeService.reloadSelectedNode.subscribe(
            () => {
                this.updateOutputProgressBars();
            }
        );
    }

    ngOnDestroy(): void {
        this.reloadSelectedNodeSubscription.unsubscribe();
    }

    // INPUT
    /**
     * Add a new InOutItem in the inputs array.
     */
    addInput(): void {
        if (this.nodeService.selectedNode) {
            (<CustomDataOperation>(
                this.nodeService.selectedNode.custom_data
            )).inputs.push(new InOutItem());
            this.inputArraySubject.next(
                (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                    .inputs
            );
        }

        this.openInputSchedules(
            (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                .inputs.length - 1
        );
    }

    /**
     * Open pop-over dialog (SchedulesInOutComponent) displaying details about the selected InOutItem.
     * @param index
     */
    openInputSchedules(index: number): void {
        this.dialogRef = this._matDialog.open(SchedulesInOutComponent, {
            data: {
                inOutItem: (<CustomDataOperation>(
                    this.nodeService.selectedNode.custom_data
                )).inputs[index],
                input: true
            },
            panelClass: 'dialogWide'
        });
    }

    /**
     * Remove the InOutObject defined by the index from the inputs array.
     * @param index
     */
    removeInput(index: number): void {
        if (
            this.nodeService.selectedNode &&
            (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                .inputs.length > index
        ) {
            (<CustomDataOperation>(
                this.nodeService.selectedNode.custom_data
            )).inputs.splice(index, 1);
            this.inputArraySubject.next(
                (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                    .inputs
            );
        }
    }

    // OUTPUT
    /**
     * Add a new InOutItem in the outputs array.
     */
    addOutput(): void {
        if (this.nodeService.selectedNode) {
            (<CustomDataOperation>(
                this.nodeService.selectedNode.custom_data
            )).outputs.push(new InOutItem());
            this.outputArraySubject.next(
                (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                    .outputs
            );
        }
        this.updateOutputProgressBars();

        this.openOutputSchedules(
            (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                .outputs.length - 1
        );
    }

    /**
     * Open pop-over dialog (SchedulesInOutComponent) displaying details about the selected InOutItem.
     * @param index
     */
    openOutputSchedules(index: number): void {
        this.dialogRef = this._matDialog.open(SchedulesInOutComponent, {
            data: {
                inOutItem: (<CustomDataOperation>(
                    this.nodeService.selectedNode.custom_data
                )).outputs[index],
                input: false
            },
            panelClass: 'dialogWide'
        });

        this.dialogRef.afterClosed().subscribe(() => {
            this.reloadSelectedNodeProgressBarStatus();
        });
    }

    /**
     * Remove the InOutObject defined by the index from the outputs array.
     * @param index
     */
    removeOutput(index: number): void {
        if (
            this.nodeService.selectedNode &&
            (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                .outputs.length > index
        ) {
            (<CustomDataOperation>(
                this.nodeService.selectedNode.custom_data
            )).outputs.splice(index, 1);
            this.outputArraySubject.next(
                (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                    .outputs
            );
        }
    }

    /**
     * Reloads selected node and progress bar statuses of its outputs
     */
    reloadSelectedNodeProgressBarStatus(): void {
        this.nodeService.selectedNode.custom_data = CustomDataOperation.loadCustomDataOperation(
            <CustomDataOperation>this.nodeService.selectedNode.custom_data
        );
        this.updateOutputProgressBars();
    }

    /**
     * Updates values for progress bars of the selected node outputs
     */
    updateOutputProgressBars(): void {
        this.outputPercentagesFalse = [];
        this.outputPercentagesTrue = [];
        for (
            let i = 0;
            i <
            (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                .outputs.length;
            i++
        ) {
            this.outputPercentagesFalse.push(
                this.getIndexPercentageOfOutput(i, false)
            );
            this.outputPercentagesTrue.push(
                this.getIndexPercentageOfOutput(i, true)
            );
        }
    }

    /**
     * Returns string with percentage value of the progress of the output defined by the index.
     * Inverted == false
     *    percentage = (sum of values of output schedules with non-empty targetDate) / (sum of values of all output schedules)
     *    This value is displayed as a label on the progress bar.
     *
     * Inverted == true
     *    percentageInverted = 1 - percentage; Also, if the percentage is less than 5%, then it is increased to 5%
     *    This value is used for the style of the progress bar - right value
     *
     * @param index
     * @param inverted
     */
    getIndexPercentageOfOutput(index: number, inverted: boolean): string {
        let percentage =
            (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                .pcsCurrentArr[index] /
            (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                .pcsTargetArr[index];

        if (inverted) {
            // just for styling of the progress bar - this percent-number is used in style.right

            let percentageInverted = 1 - percentage;
            if (percentageInverted > 1.0 || isNaN(percentageInverted)) {
                percentageInverted = 1.0;
            }

            if (percentageInverted >= 0) {
                return `${percentageInverted * 100}%`;
            }
            return '100%';
        } else {
            percentage = Math.round(percentage * 100);
            if (percentage >= 0) {
                if (percentage <= 100) {
                    return `${percentage}%`;
                } else {
                    return '100%';
                }
            }
            return '0%';
        }
    }

    // EQUIPMENT
    /**
     * Add new Equipment object in the equipment array
     */
    addEquipment(): void {
        if (this.nodeService.selectedNode) {
            (<CustomDataOperation>(
                this.nodeService.selectedNode.custom_data
            )).equipments.push(new Equipment());
            this.equipmentArraySubject.next(
                (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                    .equipments
            );
        }
    }

    /**
     * Remove Equipment object defined by the index from equipment array.
     * @param index
     */
    removeEquipment(index: number): void {
        if (
            this.nodeService.selectedNode &&
            (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                .equipments.length > index
        ) {
            (<CustomDataOperation>(
                this.nodeService.selectedNode.custom_data
            )).equipments.splice(index, 1);
            this.equipmentArraySubject.next(
                (<CustomDataOperation>this.nodeService.selectedNode.custom_data)
                    .equipments
            );
        }
    }

    /**
     * Open pop-over dialog (EquipmentMeterComponent) to display details about the Equipment specified by the index.
     * @param index
     */
    openEquipmentMeters(index: number): void {
        this.dialogRef = this._matDialog.open(EquipmentMeterComponent, {
            data: {
                equipment: (<CustomDataOperation>(
                    this.nodeService.selectedNode.custom_data
                )).equipments[index]
            },
            panelClass: 'dialogWide'
        });
    }
}

// Output datasource helper class
class InOutArrayDataSource extends DataSource<InOutItem> {
    /**
     * Constructor
     *
     * @param productionPlanService
     */
    constructor(public inOutArrayChanged: BehaviorSubject<Array<InOutItem>>) {
        super();
    }

    /**
     * Connect
     *
     * @returns {Observable<InOutItem[]>}
     */
    connect(): Observable<Array<InOutItem>> {
        return this.inOutArrayChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {}
}

// Equipment datasource helper class
class EquipmentArrayDataSource extends DataSource<Equipment> {
    /**
     * Constructor
     *
     * @param productionPlanService
     */
    constructor(
        public equipmentArrayChanged: BehaviorSubject<Array<Equipment>>
    ) {
        super();
    }

    /**
     * Connect
     *
     * @returns {Observable<Equipment[]>}
     */
    connect(): Observable<Array<Equipment>> {
        return this.equipmentArrayChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {}
}
