import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-production-import-dialog',
    templateUrl: './import-plan-dialog.component.html',
    styleUrls: ['./import-plan-dialog.component.scss']
})
export class ImportPlanDialogComponent {
    json: string;

    constructor(
        public dialogRef: MatDialogRef<ImportPlanDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {}

    /**
     * Close the dialog with message of the inserted string.
     */
    import(): void {
        this.dialogRef.close(this.json);
    }

    /**
     * Close the dialog without any message.
     */
    close(): void {
        this.dialogRef.close();
    }
}
