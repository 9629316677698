import { Schedule } from './Schedule';

export class TargetSchedule extends Schedule {
    orderId: string;

    constructor(item: any) {
        super(item);
        if (item) {
            this.orderId = item.orderId;
        } else {
            this.orderId = `${`${Math.round(
                Math.random() * 100000000
            )}`.padStart(9, '0')}${Schedule.counter++}`;
        }
    }

    getSchedule(): Schedule {
        return {
            id: this.id,
            targetDate: this.targetDate,
            realDate: this.realDate,
            quantity: this.quantity
        };
    }
}
