import { Schedule } from './Schedule';
import { IdService } from '../services/id.service';

export class InOutItem {

    static defaultUnit = 'PIECE';

    id = IdService.getRandomId();
    level = 1;
    schedule: Array<Schedule> = [];
    unit = InOutItem.defaultUnit;
}
