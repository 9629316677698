import { NodeService } from './../../../services/node.service';
import { MeterStatusesComponent } from './meter-values/meter-statuses.component';
import { Component, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { Meter, Equipment } from '../../../models';

@Component({
    selector: 'equipment-meter',
    templateUrl: './equipment-meter.component.html',
    styleUrls: ['./equipment-meter.component.scss'],
    animations: fuseAnimations
})
export class EquipmentMeterComponent {
    equipment?: Equipment;

    metersDataSource: MetersDataSource;
    metersDisplayedColumns = [
        'meterId',
        'meterUnit',
        'meterWarningLowValue',
        'warningHighValue',
        'meterButtons'
    ];
    metersArraySubject = new BehaviorSubject<Array<Meter>>([]);

    dialogRef: any;

    constructor(
        public _matDialog: MatDialog,
        public _matDialogRef: MatDialogRef<EquipmentMeterComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        public nodeService: NodeService
    ) {
        if (_data['equipment'] != null) {
            this.equipment = _data['equipment'];
            this.metersArraySubject = new BehaviorSubject(
                this.equipment.meters
            );
            this.metersDataSource = new MetersDataSource(
                this.metersArraySubject
            );
        }
    }

    /**
     * Add a new Meter in the meters array.
     */
    addMeter(): void {
        this.equipment.meters.push(new Meter());
        this.metersArraySubject.next(this.equipment.meters);
    }

    /**
     * Open pop-over dialog (MeterStatusesComponent) with details about the selected meter.
     */
    openMeterValues(index: number): void {
        this.dialogRef = this._matDialog.open(MeterStatusesComponent, {
            data: {
                equipment: this.equipment,
                meter: this.equipment.meters[index]
            },
            panelClass: 'dialogWide'
        });
    }

    /**
     * Remove meter on the specified index from the meters array.
     */
    removeMeter(index: number): void {
        this.equipment.meters.splice(index, 1);
        this.metersArraySubject.next(this.equipment.meters);
    }
}

// Equipment datasource helper class
class MetersDataSource extends DataSource<Meter> {
    /**
     * Constructor
     *
     * @param productionPlanService
     */
    constructor(public metersArrayChanged: BehaviorSubject<Array<Meter>>) {
        super();
    }

    /**
     * Connect
     *
     * @returns {Observable<Array<Meter>>}
     */
    connect(): Observable<Array<Meter>> {
        return this.metersArrayChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {}
}
