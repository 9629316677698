import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'production-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {}

    /**
     * Close dialog with 'save' message - the plan will be saved prior to navigating back
     */
    save(): void {
        this.dialogRef.close('save');
    }

    /**
     * Close dialog with 'discard' message - the plan will NOT be saved prior to navigating back
     */
    discard(): void {
        this.dialogRef.close('discard');
    }

    /**
     * Close dialog with 'cancel' message - user will not be navigated back
     */
    cancel(): void {
        this.dialogRef.close('cancel');
    }
}
