import { Component, Inject } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataSource } from '@angular/cdk/table';
import { Collaboration } from './models/Collaboration';

import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../../authentication/services/authentication.service';

@Component({
    selector: 'colaboration-picker-dialog',
    templateUrl: './colaboration-picker-dialog.component.html',
    styleUrls: ['./colaboration-picker-dialog.component.scss'],
    animations: fuseAnimations
})
export class ColaborationPickerDialogComponent {
    colaborationsArray: Collaboration[] = [];
    colaborationItemsSubject = new BehaviorSubject<any>([]);
    colaborationItemsDataSource: ColaborationsArrayDataSource;

    colaborationsColumns = ['id', 'name'];

    constructor(
        public _matDialogRef: MatDialogRef<ColaborationPickerDialogComponent>,
        private http: HttpClient,
        private authService: AuthenticationService,
        @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
        this.http.get<Collaboration[]>(
            environment.collaborationServiceUrl + '/collaborations/', 
            {
                observe: 'body', 
                responseType: 'json', 
                headers: 
                {
                    'Authorization': 'Bearer ' + this.authService.token.getJwtToken()
                }
            })
            .subscribe(
                response => {
                    if (response.length === 0) {
                        this.setDummyColaboration();
                        return;    
                    }
                    
                    this.colaborationsArray = response;

                    this.colaborationItemsSubject = new BehaviorSubject(
                        this.colaborationsArray
                    );
                    this.colaborationItemsDataSource = new ColaborationsArrayDataSource(
                        this.colaborationItemsSubject
                    );
                },
                (error) => {
                    // TODO - remove dummy colaboration
                    if (this.colaborationsArray.length === 0) {
                        this.setDummyColaboration();
                    }
                }
            );
    }

    cancel(): void {
        this._matDialogRef.close();
    }

    selectColaboration(id: string): void {
        this._matDialogRef.close(id);
    }

    setDummyColaboration(): void {
        const dummyColaboration = new Collaboration();
        dummyColaboration.id = 'colaboration-id-dummy-001';
        dummyColaboration.cft = { title: 'Dummy colaboration' };
        this.colaborationsArray = [ dummyColaboration ];

        this.colaborationItemsSubject = new BehaviorSubject(
            this.colaborationsArray
        );
        this.colaborationItemsDataSource = new ColaborationsArrayDataSource(
            this.colaborationItemsSubject
        );
    }
}

// ColaborationItem datasource helper class
export class ColaborationsArrayDataSource extends DataSource<Collaboration> {
    /**
     * Constructor
     *
     * @param productionPlanService
     */
    constructor(
        public colaborationsArrayChanged: BehaviorSubject<Array<Collaboration>>
    ) {
        super();
    }

    /**
     * Connect
     *
     * @returns {Observable<Collaboration[]>}
     */
    connect(): Observable<Array<Collaboration>> {
        return this.colaborationsArrayChanged;
    }

    /**
     * Disconnect
     */
    disconnect(): void {}
}
